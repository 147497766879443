@import 'variables.less';

.metrics-container {
  width: 400px;

  a, a:hover {
    color: unset;
  }

  .loading-layer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
  }

  .no-data {
    color: rgba(0, 0, 0, 0.45);
    margin: auto;
    text-align: center;
    padding: 10px;
  }

}


.export-all-metrics-btn {
  span:first-child {
    width: 25px;
    display: inline-block;
    vertical-align: middle;
  }
}

