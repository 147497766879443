@import "variables.less";

.and-row {
  margin-bottom: 8px;
  border: 1px solid @gray-1;
  background-color: @gray-2;
  display: inline-block;
  height: 24px;
  width: 56px;
  border-radius: 6px;
  text-align: center;
  line-height: 1.5;
}
