@import 'variables.less';

.filter-bar {
  background-color: @grey-1;
}

.combination-filter {
  user-select: none;
  font-size: .75rem;
  overflow-x: auto;
  margin-bottom: 5px;
  max-width: 100%;

  border-radius: 6px;
  border: 1px solid @grey-5;
  border-color: @grey-5;

  padding: 4px 15px 4px 10px;
  line-height: 20px;

  position: relative;
  overflow: hidden;

  outline: none;
  &.selected {
    background: #d8e6f8;
  }

  .checkbox {
    margin-right: 3px !important;
  }
  &:hover {
    border-color: #91caff;
    .delete-btn {
      display: block;

      transform: scale(1);
      opacity: 1;
    }
  }

  .delete-btn {
    position: absolute;
    top: 2px;
    bottom: 2px;
    border-radius: 6px;
    right: -25px;
    right: 0;
    transform: scale(0);
    transition: all .2s ease-in-out;
    // background: red;
    opacity: 0;

    box-shadow: -3px 0px 3px 0px @grey-5;
    top: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    outline: none;
    text-align: center;

    &:hover {
      background: @grey-4;
    }
    &:active {
      background-color: #ffccc7;
    }
    span {
      vertical-align: 0px!important;
    }
    svg {
      color: @app-blue;
    }
  }

  .ribbon {
    margin-right: .5rem;
    width: 4px;
    padding-left: 2px;
    padding-right: 2px;

  }

  .metricName,
  .rules,
  .and,
  .or,
  .timestamp {
    padding: 0 4px;
    color: @app-blue;
  }

  .rules {
    color: black;
  }
}
