@import "variables.less";

.footer {
  border-top: 1px solid #eaeaea;
  height: 25px;
  background-color: #fff;
  font-size: 10px;
  text-align: center;
  margin: 5px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
