@import "variables.less";

.menu {
  :global {
    background-color: var(--dropdown-menu-background, #fff);
    border: var(--dropdown-menu-border, none);

    .ant-dropdown-menu-item-group-list {
      list-style: none;
      padding: 0;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    }

    .ant-dropdown-menu-item-group-title {
      background: #f7f7f7;
      background: var(--group-title, #f7f7f7);
      font-weight: bold;
      color: var(--dropdown-menu-item-group-title, rgb(0 0 0 / 45%));
    }

    .ant-dropdown-menu-item {
      padding-left: 1.5rem;
      color: var(--dropdown-menu-item-color, rgb(0 0 0 / 65%));
    }

    .ant-dropdown-menu-item:hover {
      background-color: #e6f7ff;
      background-color: var(--dropdown-menu-item-hover, #e6f7ff);
    }

    .ant-dropdown > .ant-dropdown-menu {
      background-color: var(--dropdown-menu-background, #fff);
    }

    .ant-dropdown-menu-item-group {
      border: 1px solid var(--dropdown-border-color);
      background: var(--tab-dropdown-background, #fff);
    }

    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
      color: var(--dropdown-menu-item-selected-color, #1890ff);
      background-color: var(--dropdown-menu-item-hover, #e6f7ff);
    }
  }
}

.active {
  background-color: @app-blue;
  color: @app-panel-bg-color;
}
