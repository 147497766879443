@import "variables.less";

// Filter dropdown - fdd
.fdd-container {
  position: relative;
  width: 100%;

  .header-title {
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      line-height: 0.9;
      position: relative;
      display: inline-block;

      .title-holder {
        @height: 2rem;

        @pl: 0.8rem;

        cursor: pointer;
        margin: 0.1rem 0 0.2rem;
        display: block;
        position: relative;
        height: @height;
        max-height: @height;

        .title {
          display: block;
          margin: 0 auto;
          max-height: @height;
          line-height: @height;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: @pl;
          padding-bottom: 0.5rem;
          background-color: #f6f6f6;
          padding: 0 0.5rem 0.2rem @pl;
          max-width: 100%;
          overflow-x: hidden;
          min-height: @height;

          &:hover {
            outline: 1px solid #ccc;
          }
        }

        &:focus {
          outline: none;
        }

        .vertical-bar {
          position: absolute;
          width: 0.3rem;
          top: 0;
          left: 0;
          bottom: 0;
          background: orange;
          z-index: 1;
        }
      }
    }

    &.wrap-line {
      h2 {
        font-size: 14px;
        white-space: normal;

        .title-holder {
          white-space: normal;
        }

        .title {
          white-space: normal;
          line-height: 16px;
        }
      }
    }

    &.dropdown-active {
      border-color: @grey-5;

      h2 {
        transform: scale(0.85) translateX(5px);
        transform-origin: left;

        .title {
          text-shadow: 0 0 0 black;
          letter-spacing: -1px;
        }
      }
    }
  }

  .fdd-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid @grey-4;
    background-color: @grey-1;
    padding: 1rem;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 30%);
    border-radius: 3px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

.delimiter {
  color: @app-blue;
  padding: 0 0.25rem;
}

.spl-filter {
  color: @app-blue;
}
