@import "variables.less";

@bottom: 16px;
@width: 595px;

.date-picker {
  position: relative;
}

.popup {
  position: absolute;
  background-color: white;
  display: flex;
  min-height: 420px;
  padding-top: 4px;
  right: 0;
  border-radius: 4px;
  border: 1px solid white;

  :global {

    .ant-menu-vertical>.ant-menu-item,
    .ant-dropdown-menu-vertical>.ant-dropdown-menu-item {
      height: auto;
      font-size: 14px;
      line-height: 22px;
      padding: 5px 24px 5px 12px;
      background-color: #fff;
      transition: none;

      &:hover {
        background-color: #e6f7ff;
      }
    }

    .ant-picker-range {
      margin-top: 4px !important;
      margin-bottom: 4px !important;
      width: 95%;
    }

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }

    .ant-picker-input>input {
      text-align: center !important;
    }
  }

}

.openLeft {
  right: auto;
  left: 0;
}

.main {
  width: @width;
  position: relative;
  text-align: center;

  :global {
    .ant-calendar-picker {
      opacity: 0;
    }
    .ant-picker-panel-container {
      box-shadow: none!important;
    }
  }

}

.interval-selector {
  position: absolute;
  bottom: @bottom;
  left: 5%;
  text-align: left;
}

.separator {
  min-width: 90%;
  border-bottom: 1px solid #dadada;
  position: absolute;
  bottom: 85px;
  left: 5%;
}

.apply-btn {
  position: absolute !important;
  right: 5%;
  bottom: @bottom;
}
