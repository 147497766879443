.new-filter-panel {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  :global {
    .ant-row {
      width: 100% !important;
    }
    .ant-legacy-form-item-control {
      line-height: normal;
    }
    .ant-legacy-form-item {
      margin-bottom: 20px;
    }
  }
}

.alert-panel {
  margin: 1rem;
}
