@import 'variables.less';

.dashboard-tabs-wrapper {
  display: flex;
  flex: 1;

  .close-tabs-context-wrapper {
    max-width: 100%;
    display: inline-flex;

    a {
      text-decoration: none;
    }

    >.tab:first-child {
      margin-left: 0;
    }
  }
}

.tab {
  margin-left: -1px;
  display: inline-block;
  padding: 0 0.5rem 0 0.5rem;
  font-size: 12px;
  height: 25px;
  line-height: 24px;
  border-radius: 0 !important;
  background-color: @grey-3;
  background-color: var(--tabNormal, #f4f6f7);
  font-weight: 400;
  color: var(--secondaryOneNormal, #262626);
  border: 1px solid @grey-5;//#d9d9d9
  border: 1px solid var(--tabBorder, #d9d9d9);
  border-right-color: var(--borderOne, #d9d9d9);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:hover {
    border-color: @app-blue;
    border-color: var(--tabHoverBorder,#215bff);
    color: var(--secondaryOneNormal, #262626);
    background-color: var(--tabHoverBackground, #f4f6f7);
    z-index: 10;
    > a {
      background-color: transparent;
    }
  }
  > a {
    background-color: transparent;
  }
  &.fixed {
    overflow: visible;
  }
  &.active {
    z-index: 10;
    background-color: @app-blue;
    background-image: linear-gradient(180deg, #2D79FF 0%, #1556FF 100%);
    color: white;
    border-color: @app-blue;
  }
  &.dropdown {
    padding: 0 0.5rem;
  }
  .home-icon {
    svg {
      g {
        fill: #4fabf8;
      }
    }
    &.active {
      svg {
        g {
          fill: #fff;
        }
      }
    }
  }
  .prompt-icon {
    svg {
      path {
        fill: #868788;
        fill-rule:evenodd;
      }
    }
    &.active {
      svg {
        path {
          fill-rule:evenodd;
          fill: #fff;
        }
      }
    }
  }
}

.ant-modal-mask {
  opacity: 0.7;
}

.hide-tabs {
  display: none;
}
