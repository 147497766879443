@import "variables.less";

.filter-name-wrapper {
  vertical-align: top;
}

.vertical-bar {
  position: absolute;
  width: 0.3rem;
  top: 0;
  left: 0;
  bottom: 0;
  background: orange;
  z-index: 1;
}
