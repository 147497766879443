.loading-wrapper {
  min-height: 300px;
}

.row {
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
    text-align: right;
  }

  .input {
    width: 100%;
  }

  .textarea {
    width: 100%;
    height: 5em;
  }
}
