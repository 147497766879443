.shortcuts {
  height: 100%;
  overflow-y: hidden;
  min-width: 128px;

  .LiveModeMarker {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: red;
    margin-right: 2px;
  }

  .rtshortcut {
    display: flex;
    align-items: center;
  }

  :global {
    .ant-menu-item {
      line-height: 24px;
      height: 24px;

      &.ant-menu-item-selected {
        font-weight: 600;
      }
    }
  }
}
