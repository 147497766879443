@space: 8px;

@blue: #1890ff;

@blue-bg: #e6f7ff;

@gray-bg: #f6f6f6;

.to,
.display,
.interval,
.date,
.year {
  display: inline-block;
}

.intervals {
  padding-bottom: 2px;
}

.display {
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  padding: 0 16px;
  font-size: 10px;
  background-color: @gray-bg;
  border: 1px solid @gray-bg;
  line-height: 15px;
  min-height: 32px;
  min-width: 160px;

  .rt-live-text {
    font-size: 12px;
    font-weight: 800;
    padding-right: 10px;
    padding-left: 5px;
    box-sizing: border-box;
  }

  .rt-live-date {
    font-size: 12px;
    font-weight: 400;
  }

  .LiveModeMarker {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: red;
  }

  .rt-date {
    display: flex;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 5px;
  }

  .title {
    text-align: center;
    padding-top: 2px;
    border-top: 1px solid darken(@gray-bg, 20%);
  }

  &.selected {
    border-color: @blue;
    background-color: @blue-bg;

    .title {
      color: @blue;
      border-top-color: @blue;
    }
  }
}

.to {
  padding: 0 @space;
}

.day {
  display: block;
  font-weight: 600;
}

.year {
  padding-left: @space;
}

.live {
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  padding: 0 16px;
  font-size: 16px;
  line-height: 30px;
  height: 36px;
  min-width: 160px;
  color: red;
  display: flex;
  span {
    display: inline-flex;
  }
}
