.siderStyle {
  background-color: whitesmoke !important;
  text-align: left;
  min-width: 280px !important;
  max-width: 280px !important;
}

.headerStyle {
  background-color: #f8f8f8;
}

.contentStyle {
  height: 100%;
  overflow-y: auto;
}

.layoutStyle {
  height: 100%;
}
