@import "variables.less";

.dropdown-menu {
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  border-color: transparent;
  transition: box-shadow 0.2s;
  display: inline-block;
  padding: 0.1rem 0;
  color: gray;

  &.icon {
    font-size: 1.65rem;
    line-height: 1.5rem;
    width: 26px;

    &:global(.ant-dropdown-open) {
      border-color: @grey-3;
      box-shadow: 1px 1px 0 2px whitesmoke;
    }
  }

  &:hover {
    box-shadow: 1px 1px 0 2px whitesmoke;
  }
}
