@import "variables.less";

.filter-pill {
  cursor: pointer;
  padding: 0;
  position: relative;
  display: inline-block;
  margin: 4px;
  text-align: center;

  :global {
    .ant-checkbox-wrapper {
      border: 1px solid @grey-6;
      border-radius: 6px;
      line-height: 1.7;

      &:hover {
        background-color: @blue;
      }

      &.ant-checkbox-wrapper-checked {
        border-color: @app-blue;
        color: @app-blue;
      }

      .ant-checkbox {
        display: none;
      }
    }
  }

  label {
    width: 100%;
    text-overflow: ellipsis;
  }

  .rule-text {
    max-width: 30em;
    white-space: pre-line;
  }
}
