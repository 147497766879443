@import "variables.less";

.tab-group {
  display: flex;
  max-width: 800px;

  @tab-background: @grey-3;

  .tab {
    padding: 0 0.5rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 2;
    height: auto;
    background-color: @tab-background;
    color: @grey-9;
    border: 1px solid;
    border-color: @grey-5;
    border-radius: 0 !important;
    overflow-x: hidden;
    position: relative;

    &:not(:first-child) {
      margin-left: -1px;
    }

    > span {
      white-space: nowrap;
    }

    &.fixed {
      overflow: visible;
    }

    &:not(.fixed)::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      z-index: 2;
      background: linear-gradient(to right, transparent 0%, @tab-background 35%);
    }

    &.unsaved {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100px;
        background-color: @app-blue;
        border: 1px solid white;
        z-index: 3;
        padding: 3px;
      }
    }

    &:hover {
      border-color: @app-blue;
      z-index: 1;
    }

    &.active {
      background-color: @app-blue;
      border-color: @app-blue;
      z-index: 1;
      color: white;

      &:not(.fixed)::after {
        background: linear-gradient(to right, transparent 0%, @app-blue 35%);
      }
    }
  }
}
