@space: 8px;

.day {
  padding-right: @space;
  font-weight: 600;
}

.time-selector {
  display: inline-block;

  :global {
    .ant-select {
      min-width: 80px;
    }
  }
}
