@import "variables.less";

.dropdown {
  background: white;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  padding-bottom: 0.3em;
  :global {
    .ant-dropdown-menu.ant-dropdown-menu-root {
      margin-bottom: 10px;
      border-bottom: 1px solid #e9edf0;
      border-bottom-left-radius: 0px;
      box-shadow: none;
      overflow-x: hidden;
    }
  }
}
