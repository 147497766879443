@import "variables.less";

.page-not-found {
  text-align: center;
  color: @grey-7;
  font-weight: 300;
  font-size: 1.5rem;
  padding-top: 5rem;

  .title {
    font-size: 1.7rem;
    color: @grey-9;
  }

  .desc {
    font-size: 1.3rem;
  }
}
