@import "variables.less";

@red: #d0021b;

@green: #00a150;

.trend {
  display: inline-block;
  line-height: 22px;
  font-weight: 300;
  padding-left: 5px;
  font-size: 20px;
}

.up {
  color: @green;
}

.down {
  color: @red;
}
