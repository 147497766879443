@import 'variables.less';
@tab-background: @grey-3;

.tabs-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 85%;
  .tab-wrapper {
    position: relative;
    padding: 0;
    min-width: 2px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-width: 240px;
    margin-left: -1px;
    &.unsaved::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100px;
        background-color: @app-blue;
        border: 1px solid white;
        border: var(--border1, 1px solid white);
        z-index: 3;
        padding: 3px;
    }
    > a {
      .ellipsis();
      flex-grow: 1;
      padding: 0 0.5rem;
      display: block;
      color: @grey-9;
      color: var(--tabText, #262626);
    }
    .tab-close-btn {
      display: none;
      opacity: 0;
      color: #333;
      color: var(--tabCloseIcon, #333);
      background-color: #d9d9d9;
      background-color: var(--tabClose, #d9d9d9);
      position: absolute;
      line-height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      border: 0;
      font-size: 1.25em;
      transform: scale(0.7);
      outline: none;
      box-shadow: -3px 1px 3px 0px whitesmoke;
      box-shadow: var(--tabCloseShadow, -3px 1px 3px 0px whitesmoke);

      transition: all .3s;

      & > * {
        transform: scale(.8);
      }

    }

    &:hover {
      z-index: 5;
      .tab-close-btn {
        opacity: 1;
        display: block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      z-index: 2;
      background: linear-gradient(to right, fade(@tab-background, 0%) 0%, @tab-background 35%);
      background: var(--background1);
      // border-right: 1px solid var(--borderOne, #d9d9d9);
    }

    &.active {
      background-color: @app-blue;
      border-color: @app-blue;
      z-index: 1;
      min-width: 40px;
      color: white;

      > a {
        color: @grey-1;
      }
      .tab-close-btn {
        color: white;
        background-color: rgb(0, 50, 175);
        box-shadow: -3px 1px 3px 0px @app-blue;
      }

      &::after {
        background: linear-gradient(to right, fade(@app-blue, 0%) 0%, @app-blue 35%);
      }
    }
  }
}
