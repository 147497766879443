@import "variables.less";

@space: 8px;

.day-picker {
  position: relative;
}

.popup {
  position: absolute;
  background-color: white;
  display: flex;
  padding-top: 4px;
  right: 0;
  border-radius: 4px;
  border: 1px solid white;
  min-height: 330px;
  width: 280px;

  :global {
    .ant-calendar-picker {
      opacity: 0;
    }
  }
}

.time-selector {
  position: absolute;
  bottom: @space;
  left: 5%;
}

.apply-btn {
  position: absolute !important;
  bottom: @space;
  right: 5%;
}
