.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
}
