.sharing-wrapper {
  user-select: none;
  display: flex;
  // justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  .icon {
    margin-right: 5px;
  }
}
