.recent-filter {
  margin: 0 8px 8px 0;
}

.rule-text {
  max-width: 30em;
  white-space: pre-line;
}

.filter-container {
  position: relative;

  .saved-filters {
    max-height: 100px;
    overflow-y: auto;
    width: calc(100% - 20px);

    :global {
      .ant-btn {
        @color: #696969;

        color: @color;
        border-color: @color;

        &:hover {
          color: @color;
          border-color: @color;
        }

        &:active {
          color: @color;
          border-color: @color;
        }
      }
    }
  }

  .disabled-icon {
    cursor: not-allowed;
    color: #bbb;
  }

  .manage-filters-btn {
    color: #0046ff;
    cursor: pointer;
  }
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.buttons {
  .left-btn {
    float: left;
  }

  .right-btn {
    float: right;

    button {
      margin-left: 0.25rem;
    }
  }
}
