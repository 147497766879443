.editable-select-dropdown {
  :global {
    .ant-select-item-option-state {
      display: none !important;
    }
  }

  &:global(.ant-select-dropdown) {
    :global {
      .ant-select-item-option-selected:not(
          .ant-select-item-option-disabled,
          .ant-select-item-option-active
        ) {
        background-color: transparent;
      }

      .ant-select-item-option-selected {
        padding: 0;
      }
    }
  }
}
