.sub-panel {
  float: left;
  margin-right: 8px;
  width: calc(50% - 8px);
}

.saved-filter-panel {
  border: 1px solid #e5e5e5;
  height: 264px;
  overflow-y: scroll;

  :global {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 40px !important;
    }
  }
}

.button-row {
  margin-top: 1rem;
}
