@import url('variables.less');

.warn-icon {
  color: #ff2f36 !important;
  margin-right: 8px;
}

.close-icon {
  margin-top: -6px;
}

.delete-container {
  display: flex;
  align-items: start;
  font-size: 16px;

  > span {
    white-space: nowrap;
  }
}

.container {
  background: #fff2f0;
  border: solid 1px #ffaeaa;
  border-radius: 4px;
  padding: 4px;
  margin: 4px 0 0 4px;
  display: inline-block;
}
