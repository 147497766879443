.titleStyle {
  text-align: left;
  padding: 15px 10px;
}

.rowStyle {
  padding-left: 10px;
  margin-right: 5px !important;
}

.colStyle {
  height: 200px;
  margin-bottom: 10px;
}
