.calendar {
  animation-duration: 0s !important;

  :global {
    .ant-picker-panel-container {
      .ant-picker-panel {
        border: none;
      }

      box-shadow: none;
    }

    .ant-calendar-input-wrap,
    .ant-calendar-range-middle,
    .ant-calendar-prev-year-btn,
    .ant-calendar-next-year-btn {
      display: none;
    }

    .ant-calendar-prev-month-btn {
      left: 16px;
    }

    .ant-calendar-next-month-btn {
      right: 16px;
    }

    .ant-calendar {
      box-shadow: none;
    }

    .ant-calendar-today .ant-calendar-date {
      border: none;
      font-weight: normal;
      color: inherit;
    }

    .ant-calendar-next-month-btn-day.ant-calendar-today .ant-calendar-date {
      color: rgb(0 0 0 / 25%);
    }

    .ant-calendar-selected-end-date.ant-calendar-today .ant-calendar-date {
      color: #fff;
    }

    .ant-calendar-month-select,
    .ant-calendar-year-select,
    .ant-calendar-table .ant-calendar-column-header-inner {
      font-weight: 600;
    }
  }
}
