@import 'variables.less';

.trend {
  text-align: left;
  padding-left: 4px;
}

.body-section {
  cursor: pointer;
  transition: .3s all;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.na-mode {
  pointer-events: none;
  opacity: 0.5;
}

.metric-card {
  position: relative;
  background-color: white;
  height: 200px;
  transition: all 100ms linear;
  overflow: hidden;
  border: 1px solid #E5E5E5;
  min-width: 380px;
  margin: 0px;
  border-radius: 6px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;
    :global {
      .drag-handle {
        opacity: 1;
      }
    }
  }

  &:after {
    content: '';
    border-bottom: 1px solid #e8e8e8;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 1;
  }

  &:global(.active) {
    border: 1px solid #256eff;
    background-color: @active-card-bg-color;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;
    &:after {
      display: none;
    }
  }
}


