@import "variables.less";

.filter-cell {
  cursor: pointer;
  padding: 8px 0 8px 24px;
  margin: 0;

  &:hover {
    background-color: @blue;
  }

  label {
    width: 100%;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
