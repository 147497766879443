@import "variables.less";

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  z-index: @modalmask-bg-z-index;
  background-color: @grey-5;

  & + div {
    z-index: @modalmask-bg-z-index + 1;
  }
}

.transparent {  /* when mask is used during AJAX requests */
  background-color: unset;
  z-index: @modalmask-loader-bg-z-index;
}
