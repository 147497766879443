@import "variables.less";

@space: 8px;

.interval-selector {
  width: 100%;

  :global {
    .ant-select {
      min-width: 80px;
    }
  }
}

.title {
  font-weight: 600;
  margin-bottom: @space;

  span {
    margin-left: 8px;
    font-weight: normal;
  }
}

.to {
  padding: 0 @space;
}
