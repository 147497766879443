.empty-placeholder {
  text-align: center;
  margin: auto;

  .image-holder {
    margin-top: 2rem;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .text {
    color: rgb(0 0 0 / 45%);
    margin: auto;
    text-align: center;
    padding: 0.5rem;
  }
}
