@import url('variables.less');

@background: #f6f6f6;

@border-color: #dadada;

@border-radius: 6px;

.summary-container {
  position: sticky;
  top: 0;
  z-index: 20;
  margin: 0 5px;
  padding-right: 10px;
  background-color: #F5F5F5;

  .controls {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-right: 0;
    align-self: flex-start;
    flex-wrap: wrap;
    flex-grow: 0;
    margin-top: 3px;
  }

  .filter-container {
    flex: 1 1 0%;
    overflow: hidden;
  }

  .top-wrapper {
    padding: 0;
    display: flex;
    margin: 10px;
    margin-right: 0;
    word-break: break-all;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
  }

  .summary-card-holder {
    align-items: baseline;
    overflow: hidden;

    &:hover {
      overflow: auto hidden;
    }
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
}

.comparison-date-picker {
  padding-left: 16px;
  border-color: @border-color;
  border-radius: @border-radius;
  border-radius: 6px;
  border-left: solid 10px rgb(60 60 60 / 20%);
  // border-left: none;
  // border-top-right-radius: @border-radius;
  // border-bottom-right-radius: @border-radius;
}

.summary-date-picker {
  border-color: @border-color;
  border-radius: @border-radius;
  border-radius: 6px;
  border-left: solid 10px #0f93f54d;

  &:global(.dod) {
    // border-right: none;
    padding-right: 16px;
    border-radius: @border-radius;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
  }
}

.date-pickers {
  display: flex;
}

.vs {
  @size: 20px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  border: 1px solid @border-color;
  border-radius: 50%;
  height: @size;
  width: @size;
  line-height: @size;
  vertical-align: middle;
  text-align: center;
  background-color: white;
  z-index: 80;
}

.separator {
  border-left: 1px solid @border-color;
  position: relative;

  &:global(.selected) {
    border-left-color: #1890ff;
  }
}

.options {
  margin-right: -16px;
  margin-left: 5px;

  :global(.ant-btn-circle.ant-btn-lg) {
    height: 32px;
    width: 32px;
  }

  :global(.ant-btn) {
    border: 0;
    box-shadow: none;
  }
}

.unavailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  border: 1px solid @border-color;
  border-top-right-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
  background-color: @background;
  color: #9b9b9b;
  font-size: 12px;
}
